// Modules
import React, { useContext } from "react";
import styled from "styled-components";

// Context
import { Context } from "../../ContextProvider";

// Components
import { Events } from "../../components/Events";
import { Login } from "../../components/Login";

// Styles
const Wrapper = styled.section`
  text-align: center;
`;

// Component / Hook
export const Home = () => {
  const {
    state: { loggedIn },
  } = useContext(Context);

  return (
    <Wrapper>
      {loggedIn && <Events />}
      {!loggedIn && <Login />}
    </Wrapper>
  );
};
