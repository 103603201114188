// Modules
import React from "react";
import styled from "styled-components";

// Styles
const Wrapper = styled.div``;

const TextWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 30px 10px;
`;

const Label = styled.label`
  display: inline-block;
  position: absolute;
  transform: translateX(0);
  top: 0;
  left: 0;
  bottom: 0;
  padding: 10px 15px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.defaultTextColor};
  text-align: left;
  text-shadow: 0 1px 0 ${props => props.theme.defaultTextShadow};
  transition: all 0.3s ease-in-out, color 0.3s ease-out;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    background: ${props => props.theme.primaryColor};
    z-index: -1;
    transform: translate(0);
    transition: all 0.3s ease-in-out;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`;

const StyledText = styled.input`
  position: relative;
  display: inline-block;
  width: 215px;
  padding: 10px 0 10px 40px;
  font-weight: 400;
  color: ${props => props.theme.primaryColor};
  background: ${props => props.theme.backgroundInput};
  border: 0;
  border-radius: 3px;
  outline: 0;
  text-indent: 60px;
  transition: all 0.3s ease-in-out;

  &:focus {
    color: ${props => props.theme.primaryColor};
    text-indent: 0;
    background: ${props => props.theme.light};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus + label {
    color: ${props => props.theme.light};
    transform: translateX(-100%);
  }
  &:focus + label:after {
    transform: translateX(100%);
  }
`;

// Definitions
type Props = {
  label?: string;
  onChange: (options: any) => void;
  type: string;
};

// Component / Hook
export const TextField = (props: Props) => {
  const { label, onChange, type } = props;
  return (
    <Wrapper>
      <TextWrapper>
        <StyledText onChange={onChange} type={type} />
        {label && <Label>{label}</Label>}
      </TextWrapper>
    </Wrapper>
  );
};
