// Modules
import React, { useContext } from "react";
import styled from "styled-components";

// Context
import { Context } from "../../ContextProvider";

// Images
import LogoWhite from "../../images/logo-white.png";

// Styles
const Wrapper = styled.header`
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.light};
  padding: 10px 20px;
`;

const HeaderContent = styled.div`
  max-width: ${props => props.theme.maxContentWidth};
  margin: 0 auto;
  display: flex;
`;

const AdminTitle = styled.div`
  flex: 1;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  padding-left: 20px;
  font-family: "Montserrat";
  font-weight: bold;
`;

const Logout = styled.div`
  flex: 1;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  padding-left: 20px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 12px;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
    text-decoration: line-through;
  }
`;

const LogoWrapper = styled.div`
  height: 35px;
  width: 100px;
`;

const StyledLogo = styled.img`
  height: 100%;
`;

// Component / Hook
export const Header = () => {
  // Context
  const { dispatch } = useContext(Context);

  return (
    <Wrapper>
      <HeaderContent>
        <LogoWrapper>
          <a href="/">
            <StyledLogo src={LogoWhite} />
          </a>
        </LogoWrapper>
        <AdminTitle>Admin</AdminTitle>
        <Logout onClick={() => dispatch({ type: "logout" })}>Abmelden</Logout>
      </HeaderContent>
    </Wrapper>
  );
};
