// Modules
import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import * as deLocale from "date-fns/locale/de";
import { withRouter } from "react-router-dom";

// Components
import { Title } from "../Title";

// Images
import FacebookIcon from "../Icons/Facebook";
import TicketIcon from "../Icons/Ticket";
import EditIcon from "../Icons/Edit";
import DeleteIcon from "../Icons/Delete";

// Styles
const Item = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 25px;
`;

const DateWrapper = styled.div`
  flex: 1;
  max-width: 120px;
  font-family: "Montserrat";
  font-weight: 100;
`;

const Month = styled.div`
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Day = styled.div`
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 5px;
`;

const Year = styled.div`
  text-transform: uppercase;
  opacity: 0.5;
  font-size: 12px;
`;

const ActionWrapper = styled.div`
  flex: 1;
  max-width: 100px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 25px;
  font-size: 12px;
`;

const StyledTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: inherit;
  padding: 0;
`;

const Location = styled.div`
  opacity: 0.5;
`;

const LinkWrapper = styled.div`
  margin-top: 10px;
  display: flex;
`;

const StyledFacbookIcon = styled(FacebookIcon)`
  fill: black;
  width: 20px;
  transition: ${props => props.theme.hoverEffect} fill;
  margin-right: 5px;

  &:hover {
    fill: ${props => props.theme.primaryColor};
  }
`;

const StyledTicketIcon = styled(TicketIcon)`
  fill: black;
  width: 20px;
  transition: ${props => props.theme.hoverEffect} fill;
  margin-right: 5px;

  &:hover {
    fill: ${props => props.theme.primaryColor};
  }
`;

const StyledEditIcon = styled(EditIcon)`
  fill: ${props => props.theme.primaryColor};
  margin-right: 10px;
  width: 30px;
  transition: ${props => props.theme.hoverEffect} fill;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  fill: ${props => props.theme.primaryColor};
  width: 30px;
  transition: ${props => props.theme.hoverEffect} fill;
`;

const SocialLink = styled.a`
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.defaultTextColor};
  transition: ${props => props.theme.hoverEffect} color;

  &:hover {
    color: ${props => props.theme.primaryColorHover};

    svg {
      fill: ${props => props.theme.primaryColorHover};
    }
  }
`;

const FacebookLink = styled(SocialLink)`
  max-width: 110px;
  margin-right: 25px;
`;

// Component / Hook
export const EventItemBase = (props: any) => {
  const {
    history,
    item: { id, title, date, location, facebookUrl, ticketUrl },
  } = props;

  // Format date
  const month = format(date, "MMMM", { locale: deLocale });
  const day = format(date, "DD", { locale: deLocale });
  const year = format(date, "YYYY", { locale: deLocale });

  return (
    <Item>
      <DateWrapper>
        <Month>{month}</Month>
        <Day>{day}</Day>
        <Year>{year}</Year>
      </DateWrapper>
      <ContentWrapper>
        <StyledTitle label={title} isSmall />
        <Location>
          Location: {location} | Beginn:{" "}
          {format(date, "HH:mm", { locale: deLocale })} Uhr
        </Location>
        <LinkWrapper>
          {facebookUrl && facebookUrl.length > 7 && (
            <FacebookLink href={facebookUrl} target="_blank">
              <StyledFacbookIcon /> Veranstaltung
            </FacebookLink>
          )}

          {ticketUrl && ticketUrl.length > 2 && (
            <SocialLink href={ticketUrl} target="_blank">
              <StyledTicketIcon /> Tickets
            </SocialLink>
          )}
        </LinkWrapper>
      </ContentWrapper>
      <ActionWrapper>
        <span onClick={() => history.push({ pathname: `edit/${id}` })}>
          <StyledEditIcon />
        </span>
        <StyledDeleteIcon />
      </ActionWrapper>
    </Item>
  );
};

export const EventItem = withRouter(EventItemBase);
