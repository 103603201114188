// Modules
import React from "react";
import styled from "styled-components";
// No types found for the loader so we have to ignore it 😣
// @ts-ignore
import Loader from "react-loader-spinner";

// Styles
const LoadingWrapper = styled.div`
  margin: 10px auto;
  text-align: center;
`;

// Component / Hook
export const Loading = () => {
  return (
    <LoadingWrapper>
      <Loader type="ThreeDots" color="#e6c4a3" height={60} width={60} />
    </LoadingWrapper>
  );
};
