// Modules
import React, { useState, Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
import DatePicker from "react-datepicker";

// External CSS
import "react-datepicker/dist/react-datepicker.css";

// Icons
import CalendarIcon from "../Icons/Calendar";

// Definitions
type IProps = {
  onChange?: any;
  className?: string;
  selectedDate?: any;
  label: string;
  errorText?: string;
  hasError?: boolean;
  theme?: any;
};

// Component / Hook
export const CustomDatePicker = (props: IProps) => {
  // Props
  const {
    onChange,
    selectedDate,
    className,
    label,
    hasError,
    errorText,
  } = props;

  // States
  const [currentCalendar, setCurrentCalendar] = useState();

  return (
    <Wrapper>
      <GlobalStyle />
      <Label hasError={hasError}>{label}</Label>
      <StyledDatePicker
        className={className}
        selected={selectedDate}
        onChange={onChange}
        dateFormat="dd.MM.YYYY, HH:mm"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        ref={c => setCurrentCalendar(c)}
      />
      <StyledCalendarIcon
        onClick={() => {
          console.log("clicked", currentCalendar.isCalendarOpen());
          currentCalendar.setOpen(true);
        }}
      />
      {hasError && (
        <ErrorText>
          {errorText && <Fragment>{errorText}</Fragment>}
          {!errorText && <Fragment>Bitte überprüfe die Eingabe.</Fragment>}
        </ErrorText>
      )}
    </Wrapper>
  );
};

// Styles
const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    width: 100%;
    flex: 1;
  }
  .react-datepicker__input-container {
    width: 110%;
  }

  .react-datepicker__day--selected {
    color: white!important;
    background-color: #00b1eb!important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  outline: none;
  font-family: ${props => props.theme.highlightFontFamily};
  font-size: ${props => props.theme.textFontSize};
  padding-bottom: 10px;
  width: 100%;
  flex: 1;
  cursor: pointer;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: relative;
  background: ${props => props.theme.white};
  fill: ${props => props.theme.primaryColor};
  padding: 6px 13px;
  border-radius: 0 4px 4px 0;
  top: -5px;
  right: 0;
  width: 13px;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const Label = styled.div<any>`
  flex: 1;
  max-width: 220px;
  font-weight: bold;
  text-align: left;
  font-family: ${(props: IProps) => props.theme.highlightFontFamily};
  text-transform: uppercase;
  opacity: 0.5;
  font-size: ${(props: IProps) => props.theme.textFontSize};
  color: ${(props: IProps) =>
    props.hasError ? props.theme.errorBorder : props.theme.defaultTextColor};
`;

const ErrorText = styled.div`
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
  color: ${props => props.theme.errorBorder};
  font-family: ${props => props.theme.highlightFontFamily};
`;
