// Modules
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import { Text } from "./Text";

// Helper
import { Validator } from "../../components/Events";
import { CustomDatePicker } from "../../components/CustomDatePicker";

// Styles
const Wrapper = styled.div`
  max-width: ${props => props.theme.contentMaxWidth};
  margin: 50px auto;
`;

// Definitions
interface IProps {
  onInputChange: (data: any) => void;
  event: any;
  showError?: boolean;
}

// Component / Hook
export const Form = (props: IProps) => {
  // Props
  const {
    onInputChange,
    showError = false,
    event: {
      title: oTitle = "",
      location: oLocation = "",
      ticketUrl: oTicketUrl = "",
      facebookUrl: oFacebookUrl = "",
      date: oDate,
    },
  } = props;

  // States
  const [title, setTitle] = useState(oTitle);
  const [location, setLocation] = useState(oLocation);
  const [ticketUrl, setTicketUrl] = useState(oTicketUrl);
  const [facebookUrl, setFacebookUrl] = useState(oFacebookUrl);
  const [date, setDate] = useState(oDate ? new Date(oDate) : new Date());
  const [invalidFields, setInvalidFields] = useState([]);
  console.log("TCL: Form -> date", date);

  // Trigger parent on every change
  useEffect(() => {
    const event = { title, location, ticketUrl, facebookUrl, date };
    const validatorResult = Validator(event);
    setInvalidFields(validatorResult.invalidFields);
    onInputChange({ event, validatorResult });
  }, [title, location, ticketUrl, facebookUrl, date]);

  // Helper
  // @ts-ignore
  const isInvalidField = (key: any) => invalidFields.indexOf(key) > -1;

  return (
    <Wrapper>
      <CustomDatePicker
        label="Datum & Uhrzeit"
        selectedDate={date}
        onChange={(value: any) => {
          console.log("TCL: Form -> value", value);
          setDate(value);
        }}
      />
      <Text
        label="Name"
        value={title}
        onChange={value => setTitle(value)}
        hasError={showError && isInvalidField("title")}
      />
      <Text
        label="Ort"
        value={location}
        onChange={value => setLocation(value)}
        hasError={showError && isInvalidField("location")}
      />
      <Text
        label="Facebook URL"
        value={facebookUrl}
        onChange={value => setFacebookUrl(value)}
        hasError={showError && isInvalidField("facebookUrl")}
      />
      <Text
        label="Ticket URL"
        value={ticketUrl}
        onChange={value => setTicketUrl(value)}
        hasError={showError && isInvalidField("ticketUrl")}
      />
    </Wrapper>
  );
};
