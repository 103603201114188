// Modules
import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";

// Components
import { Title } from "../../components/Title";
import { Form } from "../../components/Form";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";

// Styles
const Wrapper = styled.section`
  text-align: center;
`;

const FooterNavigation = styled.div`
  margin: 40px 0px;
  padding: 20px 0px;
`;

const CancelButton = styled(Button)`
  margin-right: 15px;
`;

// Definitions
interface IProps extends RouteComponentProps<any> {}

// Component / Hook
export const EditForm = (props: IProps) => {
  // Props
  const {
    history,
    match: {
      params: { id },
    },
  } = props;

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  // Data fetching
  const loadEvent = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/events/${id}`
    );
    const { data } = await response.json();

    setEvent(data);
    setIsLoading(false);
  };

  // Load data on start
  useEffect(() => {
    loadEvent();
  }, []);

  // Helper functions
  const onInputChange = (data: any) => {
    setNewEvent({ ...event, ...data.event });
    setIsValid(data.validatorResult.isValid);
  };

  const save = async () => {
    if (!isValid) {
      setIsSaving(false);
      setShowValidation(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BACKEND}/events/${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newEvent),
        }
      );

      if (!response.ok) {
        alert(
          "Es ist ein Fehler aufgetreten! Bitte kontaktiere den Administrator!"
        );
      }
    } catch (error) {
      console.log("TCL: save -> error", error);
      alert(
        "Es ist ein Fehler aufgetreten! Bitte kontaktiere den Administrator!"
      );
    }

    history.push({ pathname: "/" });
  };

  return (
    <Wrapper>
      <Title label="Event bearbeiten" />
      {isLoading || (isSaving && <Loading />)}
      {!isLoading && !isSaving && (
        <Fragment>
          <Form
            onInputChange={onInputChange}
            event={event}
            showError={showValidation}
          />
          <FooterNavigation>
            <CancelButton onClick={() => history.push({ pathname: "/" })} light>
              Abbrechen
            </CancelButton>
            <Button onClick={save} isDisabled={!isValid}>
              Speichern
            </Button>
          </FooterNavigation>
        </Fragment>
      )}
    </Wrapper>
  );
};

export const Edit = withRouter(EditForm);
