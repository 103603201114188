// Modules
import React from "react";
import styled from "styled-components";

// Styles
const Wrapper = styled.div`
  font-family: "Montserrat";
  font-weight: 100;
  font-size: 13px;
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.errorBorder};
  background: ${props => props.theme.errorBackground};
  padding: 15px 20px;
  border-radius: 4px;
  margin: 0 auto;
  max-width: 300px;
  color: ${props => props.theme.errorBorder};
`;

// Component / Hook
export const ValidationError = () => {
  return <Wrapper>Bitte überprüfe Deine Eingaben.</Wrapper>;
};
