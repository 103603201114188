// Modules
import React from "react";
import styled from "styled-components";

// Styles
const StyledTitle = styled.h1`
  font-size: ${(props: Props) => (props.isSmall ? "18px" : "28px")};
  line-height: 39.2px;
  margin-bottom: 20px;
  padding: 0 4px;
  font-family: "Montserrat";
  font-weight: 100;
  text-transform: uppercase;
`;

// Definitions
type Props = {
  label?: string;
  isSmall?: boolean;
  className?: string;
};

// Component / Hook
export const Title = (props: Props) => {
  const { label, isSmall, className } = props;
  return (
    <StyledTitle isSmall={isSmall} className={className}>
      {label}
    </StyledTitle>
  );
};
