// Modules
import React from "react";
import styled from "styled-components";
import { version } from "../../../package.json";

// Styles
const Wrapper = styled.footer`
  background: ${props => props.theme.primaryColor};
  padding: 80px 0;
  text-align: center;
  margin-top: 80px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat";
`;

const Version = styled.div`
  opacity: 0.5;
  margin-top: 5px;
  font-size: 10px;
`;

// Component / Hook
export const Footer = () => {
  return (
    <Wrapper>
      &copy; VAIT {new Date().getFullYear()} <Version>{version}</Version>
    </Wrapper>
  );
};
