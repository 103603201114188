// Modules
import React, { Fragment } from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  label: string;
  value?: string;
  description?: string;
  onChange: (options: any) => void;
  theme?: any;
  errorText?: string;
  hasError?: boolean;
}

// Styles
const Wrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const Input = styled.input`
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  outline: none;
  font-family: ${props => props.theme.highlightFontFamily};
  font-size: ${props => props.theme.textFontSize};
  padding-bottom: 10px;
  width: 100%;

  &:focus {
    border-bottom: 1px solid ${props => props.theme.primaryColor};
  }
`;

const Label = styled.div<any>`
  flex: 1;
  max-width: 220px;
  font-weight: bold;
  text-align: left;
  font-family: ${props => props.theme.highlightFontFamily};
  text-transform: uppercase;
  opacity: 0.5;
  font-size: ${props => props.theme.textFontSize};
  color: ${(props: IProps) =>
    props.hasError ? props.theme.errorBorder : props.theme.defaultTextColor};
`;

const ErrorText = styled.div`
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
  color: ${props => props.theme.errorBorder};
  font-family: ${props => props.theme.highlightFontFamily};
`;

// Component / Hook
export const Text = (props: IProps) => {
  const { value = "", label, onChange, hasError, errorText } = props;

  return (
    <Wrapper>
      <Label hasError={hasError}>{label}</Label>
      <InputWrapper>
        <Input
          value={value}
          onChange={(options: any) => onChange(options.target.value)}
        />
        {hasError && (
          <ErrorText>
            {errorText && <Fragment>{errorText}</Fragment>}
            {!errorText && <Fragment>Bitte überprüfe die Eingabe.</Fragment>}
          </ErrorText>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
