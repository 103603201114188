// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Styles
const StyledIntro = styled.p`
  line-height: ${props => props.theme.textLineHeight};
  margin: 10px 0;
`;

// Definitions
type Props = {
  children: ReactChild | Array<ReactChild>;
};

// Component / Hook
export const Intro = (props: Props) => {
  const { children } = props;
  return <StyledIntro>{children}</StyledIntro>;
};
