// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  onClick: () => void;
  children: ReactChild;
  light?: boolean;
  isDisabled?: boolean;
  className?: string;
}

// Styles
const StyledButton = styled.button<IProps>`
  background: ${props =>
    props.light ? props.theme.light : props.theme.darkButtonBackground};
  color: ${props =>
    props.light ? props.theme.defaultTextColor : props.theme.light};
  border: 1px solid
    ${props =>
      props.light ? props.theme.light : props.theme.darkButtonBackground};
  font-size: 12px;
  font-family: ${props => props.theme.highlightFontFamily};
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: ${props => props.theme.defaultBorderRadius};
  transition: all 0.2s ease-in-out
  opacity: ${props => (props.isDisabled ? 0.5 : 1)}
  
  
  &:hover {
    cursor: ${props => (props.isDisabled ? "default" : "pointer")}
    color: ${props => props.theme.light};
    background: ${props => props.theme.darkButtonBackgroundHover};
  }
`;

// Component / Hook
export const Button = (props: IProps) => {
  const { onClick, children, light, className, isDisabled } = props;
  return (
    <StyledButton
      onClick={onClick}
      light={light}
      className={className}
      isDisabled={isDisabled}
    >
      {children}
    </StyledButton>
  );
};
