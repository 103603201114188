// Modules
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// Components
import { Title } from "../Title";
import { Loading } from "../Loading";
import { Intro } from "../Intro";
import { EventItem } from "./EventItem";

// Icons
import CreateIcon from "../Icons/Create";

// Styles
const Wrapper = styled.section`
  text-align: center;
`;

const EventsWrapper = styled.div`
  padding: 25px;
  max-width: ${props => props.theme.contentMaxWidth};
  margin: 0 auto;
`;

const StyledCreateIcon = styled(CreateIcon)`
  width: 30px;
  margin: 20px 0px;
  transition: ${props => props.theme.hoverEffect} fill;

  &:hover {
    fill: ${props => props.theme.primaryColorHover};
    cursor: pointer;
  }
`;

const CreateIconWrapper = styled.span``;

// Component / Hook
export const EventsBase = (props: any) => {
  // Props
  const { history } = props;

  // States
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Data fetching
  const loadEvents = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND}/events`);
    const { events } = await response.json();

    // Sort events by date
    const sortedEvents = events.sort((a: any, b: any) => {
      if (a.date > b.date) return -1;
      if (b.date > a.date) return 1;
      return 0;
    });

    setIsLoading(false);
    setEvents(sortedEvents);
  };

  // Load data on start
  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <Wrapper>
      <Title label="Events" />
      <Intro>Alle angelegten Events - nach Datum sortiert.</Intro>
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <CreateIconWrapper
            onClick={() => history.push({ pathname: `create` })}
          >
            <StyledCreateIcon />
          </CreateIconWrapper>
          <EventsWrapper>
            {!events.length && (
              <Fragment>Es wurde keine Events gefunden. 😢</Fragment>
            )}
            {events.length && (
              <Fragment>
                {events.map((item: any) => (
                  <EventItem item={item} key={item.id} />
                ))}
              </Fragment>
            )}
          </EventsWrapper>
        </Fragment>
      )}
    </Wrapper>
  );
};

export const Events = withRouter(EventsBase);
