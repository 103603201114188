// Modules
import React, { useContext, useState } from "react";
import styled from "styled-components";

// Context
import { Context } from "../../ContextProvider";

// Components
import { Button } from "../Button";
import { Intro } from "../Intro";
import { TextField } from "../TextField";
import { Title } from "../Title";

// Styles
const Wrapper = styled.section`
  text-align: center;
  margin-top: 20px;
  padding: 0px 15px;
`;

// Component / Hook
export const Login = () => {
  // Defaults
  const masterPassword = "VaitSuperAdmin";

  // Context & state
  const { dispatch } = useContext(Context);
  const [password, setPassword] = useState("");

  // Functions
  const loginUser = () => {
    if (masterPassword !== password) {
      alert("Password ist falsch!");
      return;
    }

    dispatch({ type: "login" });
  };

  const onChangePasswordInput = (options: any) =>
    setPassword(options.target.value);

  return (
    <Wrapper>
      <Title label="Anmelden" />
      <Intro>
        Du benötigst ein Passwort um in den Admin Bereich zu gelangen.
      </Intro>
      <TextField
        label="Passwort"
        onChange={onChangePasswordInput}
        type="password"
      />
      <Button onClick={loginUser}>Anmelden</Button>
    </Wrapper>
  );
};
