// Modules
import React from "react";

// Definitions
interface IProps {
  className?: string;
}

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <svg viewBox="0 0 512 512" className={className}>
      <g id="Layer_1_1_">
        <path d="M494,256c0,131.4-106.6,238-238,238S18,387.4,18,256S124.6,18,256,18S494,124.6,494,256z" />
      </g>
      <g id="Layer_2">
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          x1="114.4"
          x2="397.5"
          y1="260"
          y2="260"
        />
      </g>
    </svg>
  );
};
