// Modules
import React, { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// Screens
import { Home } from "./screens/Home";
import { Edit } from "./screens/Edit";
import { Create } from "./screens/Create";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

export const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Fragment>
        <Route path="/" exact component={Home} />
        <Route path="/edit/:id" exact component={Edit} />
        <Route path="/create" exact component={Create} />
      </Fragment>
      <Footer />
    </BrowserRouter>
  );
};
