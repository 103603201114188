// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
  onClick?: any;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${props => props.theme.dark};
`;

// Component
export default (props: IProps) => {
  const { className = "", onClick } = props;

  return (
    <StyledSVG
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path d="M1.53571 20.5357V17.0804H4.99107V20.5357H1.53571ZM5.75893 20.5357V17.0804H9.59821V20.5357H5.75893ZM1.53571 16.3125V12.4732H4.99107V16.3125H1.53571ZM5.75893 16.3125V12.4732H9.59821V16.3125H5.75893ZM1.53571 11.7054V8.25H4.99107V11.7054H1.53571ZM10.3661 20.5357V17.0804H14.2054V20.5357H10.3661ZM5.75893 11.7054V8.25H9.59821V11.7054H5.75893ZM14.9732 20.5357V17.0804H18.4286V20.5357H14.9732ZM10.3661 16.3125V12.4732H14.2054V16.3125H10.3661ZM6.14286 5.94643C6.14286 6.15039 5.96289 6.33036 5.75893 6.33036H4.99107C4.78711 6.33036 4.60714 6.15039 4.60714 5.94643V2.49107C4.60714 2.28711 4.78711 2.10714 4.99107 2.10714H5.75893C5.96289 2.10714 6.14286 2.28711 6.14286 2.49107V5.94643ZM14.9732 16.3125V12.4732H18.4286V16.3125H14.9732ZM10.3661 11.7054V8.25H14.2054V11.7054H10.3661ZM14.9732 11.7054V8.25H18.4286V11.7054H14.9732ZM15.3571 5.94643C15.3571 6.15039 15.1772 6.33036 14.9732 6.33036H14.2054C14.0014 6.33036 13.8214 6.15039 13.8214 5.94643V2.49107C13.8214 2.28711 14.0014 2.10714 14.2054 2.10714H14.9732C15.1772 2.10714 15.3571 2.28711 15.3571 2.49107V5.94643ZM19.9643 5.17857C19.9643 4.33873 19.2684 3.64286 18.4286 3.64286H16.8929V2.49107C16.8929 1.43527 16.029 0.571427 14.9732 0.571427H14.2054C13.1496 0.571427 12.2857 1.43527 12.2857 2.49107V3.64286H7.67857V2.49107C7.67857 1.43527 6.81473 0.571427 5.75893 0.571427H4.99107C3.93527 0.571427 3.07143 1.43527 3.07143 2.49107V3.64286H1.53571C0.695871 3.64286 0 4.33873 0 5.17857V20.5357C0 21.3756 0.695871 22.0714 1.53571 22.0714H18.4286C19.2684 22.0714 19.9643 21.3756 19.9643 20.5357V5.17857Z" />
    </StyledSVG>
  );
};
