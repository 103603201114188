// Modules
import React, { useReducer } from "react";

// Settings
const LOCAL_STORAGE_KEY = "vaitadmin:state";
const initialStateString = localStorage.getItem(LOCAL_STORAGE_KEY);

// State
const defaultState = { loggedIn: false };

const initialState = initialStateString
  ? JSON.parse(initialStateString)
  : defaultState;

// Context
export const Context = React.createContext(initialState);

// Reducer
let reducer = (state: any, action: any) => {
  const newState = { ...state };
  switch (action.type) {
    case "login":
      newState.loggedIn = true;
      break;
    case "logout":
      newState.loggedIn = false;
      break;
  }

  // Save into local storage
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
  return newState;
};

export const Provider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};
