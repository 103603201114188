const validateUrl = (value: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

export const Validator = (props: any) => {
  // Defaults
  let isValid = true;
  let invalidFields: any = [];

  // Validate length for fields
  ["title", "location", "date"].forEach((key: string) => {
    if (!props[key] || props[key].length < 3) {
      isValid = false;
      invalidFields.push(key);
    }
  });

  // Validate facebook url
  if (!validateUrl(props.facebookUrl)) {
    isValid = false;
    invalidFields.push("facebookUrl");
  }

  return { isValid, invalidFields };
};
