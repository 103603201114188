// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${props => props.theme.primaryColor};
`;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG viewBox="0 0 512 512" className={className}>
      <g id="Layer_1_1_">
        <path d="M494,256c0,131.4-106.6,238-238,238S18,387.4,18,256S124.6,18,256,18S494,124.6,494,256z" />
      </g>
      <g id="Layer_2">
        <g>
          <line
            fill="none"
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="16"
            x1="256"
            x2="256"
            y1="146"
            y2="374"
          />
          <line
            fill="none"
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="16"
            x1="150.5"
            x2="361.5"
            y1="260"
            y2="260"
          />
        </g>
      </g>
    </StyledSVG>
  );
};
