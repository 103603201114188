// Modules
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";

// Components
import { Title } from "../../components/Title";
import { Form } from "../../components/Form";
import { Button } from "../../components/Button";
import { ValidationError } from "../../components/ValidationError";
import { Loading } from "../../components/Loading";

// Styles
const Wrapper = styled.section`
  text-align: center;
`;

const FooterNavigation = styled.div`
  margin: 40px 0px;
  padding: 20px 0px;
`;

const CancelButton = styled(Button)`
  margin-right: 15px;
`;

// Definitions
interface IProps extends RouteComponentProps<any> {}

// Component / Hook
export const CreateForm = (props: IProps) => {
  // Props
  const { history } = props;

  // States
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  // Helper functions
  const onInputChange = (resp: any) => {
    const newEvent = { ...event, ...resp.event };
    setNewEvent(newEvent);
    setIsValid(resp.validatorResult.isValid);
  };

  const save = async () => {
    setIsSaving(true);

    if (!isValid) {
      setIsSaving(false);
      setShowValidation(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BACKEND}/events`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newEvent),
        }
      );

      if (!response.ok) {
        alert(
          "Es ist ein Fehler aufgetreten! Bitte kontaktiere den Administrator!"
        );
      }
    } catch (error) {
      console.log("TCL: save -> error", error);
      alert(
        "Es ist ein Fehler aufgetreten! Bitte kontaktiere den Administrator!"
      );
    }

    history.push({ pathname: "/" });
  };

  return (
    <Wrapper>
      <Title label="Event erstellen" />
      {isSaving && <Loading />}
      {!isSaving && (
        <Fragment>
          <Form
            onInputChange={onInputChange}
            event={{}}
            showError={showValidation}
          />
          {!isValid && showValidation && <ValidationError />}
          <FooterNavigation>
            <CancelButton onClick={() => history.push({ pathname: "/" })} light>
              Abbrechen
            </CancelButton>
            <Button onClick={save} isDisabled={!isValid}>
              Speichern
            </Button>
          </FooterNavigation>
        </Fragment>
      )}
    </Wrapper>
  );
};

export const Create = withRouter(CreateForm);
