export default {
  // Colors
  primaryColor: "#e6c4a3",
  primaryColorHover: "#bfa083",
  secondaryColor: "#c2edfa",
  light: "#fff",
  backgroundInput: "#efefef",
  defaultTextColor: "#000",
  borderColor: "#e2e2e2",
  errorBorder: "#a50303",
  errorBackground: "#f97d7d2e",

  // textFontSize
  defaultTextShadow: "rgba(255, 255, 255, 0.4)",

  // Fonts
  defaultFontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  highlightFontFamily: "Montserrat",

  // Font sizes
  textFontSize: "15px",

  // Settings
  defaultBorderRadius: "3px",
  contentMaxWidth: "700px",

  // Buttons
  darkButtonBackground: "#000",
  darkButtonBackgroundHover: "rgba(0, 0, 0, 0.8)",

  // Media queries
  maxContentWidth: "900px",

  // Effetcs
  hoverEffect: "150ms ease-in-out",
};
