// Modules
import React from "react";

// Definitions
interface IProps {
  className?: string;
}

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <svg viewBox="0 0 512 512" className={className}>
      <g id="Layer_1_1_">
        <path d="M494,256c0,131.4-106.6,238-238,238S18,387.4,18,256S124.6,18,256,18S494,124.6,494,256z" />
      </g>
      <g>
        <g>
          <path
            d="M142.9,329l-5,45l45-5    l144-144l-40-40L142.9,329z"
            fill="none"
            stroke="#FFFFFF"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="16"
          />
        </g>
        <g>
          <path
            d="M337.1,138    c-3.1,0-6.2,1.2-8.6,3.6L307.9,164l40,40l22.6-20.5c4.8-4.8,4.8-12.5,0-17.2l-24.7-24.7C343.2,139.2,340.1,138,337.1,138    L337.1,138z"
            fill="none"
            stroke="#FFFFFF"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="16"
          />
        </g>
      </g>
    </svg>
  );
};
